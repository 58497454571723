<template>
  <div
    :class="['group/item text-gray-900 hover:bg-gray-100 border rounded-lg w-full flex items-center gap-3 py-3 px-4 min-h-16 text-sm cursor-pointer',
             isFocus ? styleOnFocus : 'bg-white hover:border-gray-400 ',
             dataErrorMessage ? 'border-yellow-500' : '']"
    @click="onClick"
  >
    <div class="bg-gray-50 border rounded-md min-w-8 min-h-8 flex items-center justify-center">
      <Icon :name="option.icon" size="sm" />
    </div>
    <div class="flex flex-col">
      <p v-if="showHeader" class="text-gray-500 font-medium text-xs">{{ headerDescription }}</p>
      <div class="min-h-6 flex items-center text-sm">
        <p class="flex items-center gap-x-1 flex-wrap">
          {{ description }}
          <span v-if="option.targetData?.label">
            <UBadge variant="solid" size="xs" color="gray">{{ option.targetData?.label }}</UBadge>
          </span>
          <span v-if="option.targetData?.user?.name" class="flex items-center gap-1 ml-1">
            <Avatar
              :id="option.targetData?.user?.id"
              :src="option.targetData?.user?.photo"
              :name="option.targetData?.user?.name"
              size="2xs"
            />
            <span class="truncate">{{ option.targetData?.user?.name }}</span>
          </span>
          <span v-if="option.targetData?.status?.label">
            <StatusBadge
              class="flex items-center gap-1 text-xs hover:border-inherit max-h-6"
              :type="(option.targetData?.status?.type as unknown as STATUS_TYPE)"
              :label="option.targetData?.status?.label"
              :tooltip="{
                arrowClass: '!top-[unset]',
              }"
            />
          </span>
          <span v-if="option.targetData?.section">
            at section <UBadge variant="solid" size="xs" color="gray">{{ option.targetData?.section }}</UBadge>
          </span>
        </p>
      </div>
    </div>
    <div class="flex items-center h-full gap-1 ml-auto">
      <UTooltip
        v-if="option.triggerType !== AutomationTriggerType.BUTTON"
        :class="['invisible group-hover/item:visible ml-auto']"
        :text="'Remove ' + option.optionType.toLowerCase()"
        :popper="{
          placement: 'top',
          arrow: true,
        }"
      >
        <UButton
          color="gray" variant="ghost"
          icon="i-heroicons:trash"
          size="xs"
          @click.prevent.stop="onRemove"
        />
      </UTooltip>
      <UTooltip
        v-if="errorMessage || dataErrorMessage"
        class="ml-auto"
        :text="dataErrorMessage ? dataErrorMessage : errorMessage"
        :popper="{
          placement: 'top',
          arrow: true,
        }"
      >
        <Icon
          name="heroicons:exclamation-triangle-20-solid"
          size="20"
          :class="errorMessage ? 'text-red-500' : 'text-yellow-500'"
        />
      </UTooltip>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { AutomationOption } from '#automation/types'
import { AutomationOptionType, AutomationTriggerType } from '#automation/constants'
import type { STATUS_TYPE } from '#status/constant'

const props = defineProps({
  option: {
    type: Object as PropType<AutomationOption>,
    required: true,
  },
  showHeader: {
    type: Boolean,
    default: true,
  },
  actionIndex: {
    type: Number,
    default: 0,
  }
})

const { styleOnFocus, currentSelectOption, currentActionIndex, automationError, dataError } = useAutomationSharedState()

const errorMessage = computed(() => {
  if (props.option.optionType === AutomationOptionType.ACTION) {
    return automationError.value.actions[props.actionIndex] || ''
  }

  if (props.option.optionType === AutomationOptionType.CONDITION) {
    return automationError.value.condition || ''
  }

  return automationError.value.trigger
})

const dataErrorMessage = computed(() => {
  if (props.option.optionType === AutomationOptionType.ACTION) {
    return dataError.value.actions[props.actionIndex] || ''
  }

  if (props.option.optionType === AutomationOptionType.CONDITION) {
    return dataError.value.condition || ''
  }

  return dataError.value.trigger
})

const headerDescription = computed(() => {
  if (props.option.optionType === AutomationOptionType.TRIGGER) {
    return 'When'
  } else if (props.option.optionType === AutomationOptionType.ACTION) {
    return 'Then'
  }

  return 'Check if'
})

const description = computed(() => {
  if (props.option.targetData?.description) {
    return props.option.targetData?.description
  }

  return props.option.label
})

const isFocus = computed(() => {
  if (props.option.optionType === AutomationOptionType.ACTION) {
    return currentSelectOption.value.optionType === props.option.optionType && currentActionIndex.value === props.actionIndex
  }

  return currentSelectOption.value.optionType === props.option.optionType
})

const onClick = () => {
  currentSelectOption.value = props.option

  if (props.option.optionType === AutomationOptionType.ACTION) {
    currentActionIndex.value = props.actionIndex
  }
}

const onRemove = () => {
  currentActionIndex.value = props.actionIndex
  currentSelectOption.value = { optionType: props.option.optionType } as AutomationOption
}
</script>
