<template>
  <div class="bg-gray-50 border-l grow h-full py-10 px-16">
    <div class="w-full relative flex flex-col items-center group">
      <AutomationSetupPanelAddOption v-if="!currentRule.trigger || !currentRule.trigger.label" :type="AutomationOptionType.TRIGGER" />
      <AutomationSetupPanelOption v-else :option="currentRule.trigger" />
      <div
        v-if="!currentRule.condition"
        data-test="automation-add-condition-button"
        class="cursor-pointer text-white absolute w-4 h-4 bg-black rounded-full flex items-center justify-center -bottom-2 z-10 opacity-0 group-hover:opacity-100 duration-100"
        @click="onAddCondition"
      >
        <UTooltip
          text="Add condition..."
          :popper="{
            placement: 'right',
            arrow: true,
          }"
        >
          <Icon name="heroicons:plus-20-solid" size="12" />
        </UTooltip>
      </div>
    </div>
    <div v-if="currentRule.condition" class="w-full flex flex-col items-center">
      <div class="border border-gray-400 h-10 z-20" />
      <AutomationSetupPanelAddOption v-if="!currentRule.condition || !currentRule.condition.label" :type="AutomationOptionType.CONDITION" />
      <AutomationSetupPanelOption v-else :option="(currentRule.condition as AutomationOption)" />
    </div>
    <div class="w-full flex flex-col items-center">
      <div class="border border-gray-400 h-10" />
      <AutomationSetupPanelAddOption v-if="!existAction" :type="AutomationOptionType.ACTION" />
      <AutomationSetupPanelAction v-else />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { AutomationOptionType } from '#automation/constants'
import type { AutomationOption } from '#automation/types'

const { currentSelectOption, currentRule } = useAutomationSharedState()

const onAddCondition = () => {
  currentSelectOption.value = { optionType: AutomationOptionType.CONDITION } as AutomationOption
}

const existAction = computed(() => {
  return currentRule.value.actions?.find(action => action.label)
})
</script>
