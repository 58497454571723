<template>
  <div class="flex flex-col gap-4">
    <div class="space-y-1 mb-4">
      <p class="text-sm font-semibold">Trigger button</p>
      <p class="text-xs text-gray-500">Rule is run when it is manually triggered by the user by clicking the button in task details.</p>
    </div>
    <div class="space-y-1">
      <label class="text-xs">Button label<span class="text-red-500">*</span></label>
      <UInput v-model="currentRule.name" placeholder="Label your button" size="sm" @change="onChangeLabel" />
      <p v-if="automationError.ruleName" class="text-red-600 text-xs">{{ automationError.ruleName }}</p>
    </div>
    <div class="space-y-1">
      <label class="text-xs">Show button on</label>
      <USelectMenu
        v-model="selectedTaskTypes"
        size="md"
        multiple
        :options="taskTypeOptions"
        @change="onChangeTaskTypes"
      >
        <template #label>
          <span v-if="selectedTaskTypes?.length" class="truncate">{{ selectedTaskTypes.map(type => type.label).join(', ') }}</span>
          <span v-else>Select task types</span>
        </template>
      </USelectMenu>
      <p v-if="automationError.taskType" class="text-red-600 text-xs">{{ automationError.taskType }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  parentId: {
    type: String,
    required: true
  },
  source: {
    type: String,
    required: true
  }
})
const { currentSelectOption, currentRule, automationError, dataError } = useAutomationSharedState()
const { currentSettingsPack } = useWorkspaceSharedState()
const { boardData } = useBoardSharedState()
const isFromSettingPack = computed(() => props.source === 'settings-pack')
const selectedTaskTypes = ref<{
  label: string
  value: string
}[]>([])

const taskTypeOptions = computed(() => {
  if (isFromSettingPack.value) {
    return currentSettingsPack.value.taskTypes.map(taskType => ({
      label: taskType.name,
      value: taskType.id,
    })) || []
  }

  return boardData.value.taskTypes.filter(taskType => !taskType.settingsPackId).map(taskType => ({
    label: taskType.name,
    value: taskType.id,
  })) || []
})

const onChangeLabel = () => {
  currentSelectOption.value.targetData = {
    description: 'User manually click the button',
    label: currentRule.value.name,
  }
}

const onChangeTaskTypes = () => {
  dataError.value.taskType = ''
  currentRule.value.taskTypes = selectedTaskTypes.value.map(taskType => taskType.value)
}

onMounted(async () => {
  if (currentRule.value.taskTypes) {
    selectedTaskTypes.value = taskTypeOptions.value.filter(taskType => currentRule.value?.taskTypes?.includes(taskType.value))
  }
})
</script>
