<template>
  <UCard
    v-if="currentRule && !isLoading"
    class="ring-0 shadow-none flex-1 flex flex-col min-w-1 min-h-1"
    :ui="{
      body: {
        base: 'overflow-auto h-full scroll-stable',
        padding: '!p-0',
      },
    }"
  >
    <template #header>
      <BoardSettingsHeader
        hash="settings-automations"
        :title="title"
        last-page="automations"
        :can-edit="currentRule?.trigger?.triggerType !== AutomationTriggerType.BUTTON"
        placeholder="Name the new rule"
        @change="onChangeRuleName"
      >
        <UTooltip
          v-if="automationError.ruleName && currentRule?.trigger?.triggerType !== AutomationTriggerType.BUTTON"
          :text="automationError.ruleName"
        >
          <Icon name="heroicons:exclamation-triangle-20-solid" size="20" class="text-red-500" />
        </UTooltip>
      </BoardSettingsHeader>
    </template>
    <div class="flex h-full">
      <div class="min-w-[18.5rem] max-w-[18.5rem] h-full px-2 py-4 space-y-4">
        <AutomationSetupSidebar :parent-id="parentId" :source="source" />
      </div>
      <AutomationSetupPanel />
    </div>
    <template #footer>
      <div class="w-full flex items-end">
        <UButton
          data-test="automation-save"
          class="ml-auto"
          :label="buttonLabel"
          :disabled="!!isDisableUpdate"
          @click="updateCreateRule"
        />
      </div>
    </template>
  </UCard>
</template>

<script lang="ts" setup>
import type { AutomationRuleRaw } from '#automation/types'
import { AutomationTriggerType } from '#automation/constants'

const props = defineProps<{
  parentId: string
  source: MorphSource
}>()
const {
  currentRuleId,
  currentRule,
  automationError,
  createNewRule,
  currentSelectOption,
  updateCreateRule,
  dataError,
  convertRawData,
} = useAutomationSharedState()

const { result, load } = useLazyAutomationRuleQuery(currentRuleId.value)
const { closeSettings } = useBoardSettingsNavigator()
const toast = useToast()

const isLoading = ref(true)
const title = computed(() => {
  if (currentRule.value?.trigger?.triggerType === AutomationTriggerType.BUTTON) {
    return !currentRule.value.id ? 'Create new button' : 'Trigger button'
  }

  return currentRule.value?.name || ''
})

const isDisableUpdate = computed(() => {
  if (!currentRule.value.id) {
    return false
  }

  return dataError.value.trigger
    || dataError.value.condition
    || dataError.value.ruleName
    || currentRule.value.actions.some((_, index) => dataError.value.actions[index])
})

const buttonLabel = computed(() => {
  if (currentRule.value?.trigger?.triggerType === AutomationTriggerType.BUTTON) {
    return !currentRule.value.id ? 'Create button' : 'Update button'
  }

  return !currentRule.value.id ? 'Create rule' : 'Update rule'
})

const onChangeRuleName = (value: string) => {
  automationError.value.ruleName = ''
  currentRule.value.name = value
}

onMounted(async () => {
  if (currentRuleId.value) {
    await load()
    const rawRule = (result.value?.automationRule || {}) as AutomationRuleRaw
    if (!rawRule) {
      toast.add({
        title: 'Error',
        description: 'Something when wrong.'
      })
      closeSettings()
      return
    }

    currentRule.value = convertRawData(rawRule)
  }

  if (!currentRule.value.trigger) {
    createNewRule(props.source, props.parentId)
  }

  currentSelectOption.value = currentRule.value.trigger
  isLoading.value = false
})
</script>
