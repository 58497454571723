<template>
  <div
    :data-test="`automation-add-${type}-${props.actionIndex}`"
    :class="['group/add-item hover:bg-gray-100 border rounded-lg w-full h-16 flex items-center gap-2 px-4 text-sm cursor-pointer',
             isFocus ? styleOnFocus : 'border-dashed border-gray-400 text-gray-500']"
    @click="onClick"
  >
    <Icon name="heroicons-plus" size="sm" class="p-2" />
    {{ description }}
    <div class="ml-auto h-full flex items-center gap-1">
      <UTooltip
        v-if="canRemove"
        class="invisible group-hover/add-item:visible ml-auto"
        text="Remove condition"
        :popper="{
          placement: 'top',
          arrow: true,
        }"
      >
        <UButton
          color="gray" variant="ghost"
          icon="i-heroicons:x-mark"
          size="xs"
          @click.prevent.stop="onClickRemove"
        />
      </UTooltip>
      <UTooltip
        v-if="errorMessage"
        class="ml-auto"
        :text="errorMessage"
        :popper="{
          placement: 'top',
          arrow: true,
        }"
      >
        <Icon
          name="heroicons:exclamation-triangle-20-solid"
          size="20"
          class="text-red-500"
        />
      </UTooltip>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { AutomationOptionType } from '#automation/constants'
import type { AutomationOption } from '#automation/types'

const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  actionIndex: {
    type: Number,
    default: 0,
  }
})
const {
  styleOnFocus,
  currentSelectOption,
  removeCondition,
  removeAction,
  currentActionIndex,
  automationError,
  currentRule,
} = useAutomationSharedState()

const errorMessage = computed(() => {
  if (props.type === AutomationOptionType.ACTION) {
    return automationError.value.actions[props.actionIndex] || ''
  }

  if (props.type === AutomationOptionType.CONDITION) {
    return automationError.value.condition || ''
  }

  return automationError.value.trigger
})
const description = computed(() => {
  if (props.type === AutomationOptionType.TRIGGER) {
    return 'Add trigger...'
  } else if (props.type === AutomationOptionType.ACTION) {
    return 'Add action...'
  }

  return 'Add condition...'
})

const isFocus = computed(() => {
  if (props.type === AutomationOptionType.ACTION) {
    return currentSelectOption.value.optionType === props.type && currentActionIndex.value === props.actionIndex
  }

  return currentSelectOption.value.optionType === props.type
})

const canRemove = computed(() => {
  if (props.type === AutomationOptionType.ACTION) {
    return props.actionIndex > 0 || (props.actionIndex === 0 && currentRule.value?.actions?.length > 1)
  }

  return props.type === AutomationOptionType.CONDITION
})

const onClickRemove = () => {
  if (props.type === AutomationOptionType.CONDITION) {
    removeCondition()
  }

  if (props.type === AutomationOptionType.ACTION) {
    removeAction(props.actionIndex)
  }
}

const onClick = () => {
  if (props.type === AutomationOptionType.ACTION) {
    currentActionIndex.value = props.actionIndex
  }

  currentSelectOption.value = { optionType: props.type } as AutomationOption
}
</script>
